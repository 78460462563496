<template>
    <div class="mod">
        <caclulator-modal v-if="showCalculator" @close="showCalculator = false" :tokensTotalStake="tokensTotalStake" :pool="pool" />
        <div class="modal__inner">
            <div class="modal__up">
                <p class="modal__name">{{ lang.get("SIDEBAR_TITLE") }}</p>
                <i @click="$emit('closeStakeWindow')" class="i-close-line"></i>
            </div>
            <div class="modal__down">
                <div class="block__one">
                    <div class="first__stake">
                        <p class="your__reward">{{ lang.get("STAKE") }}</p>
                        <div class="row__days__text">
                            <i class="i-question-line"></i>
                            <span class="question-line-text">
                                {{ lang.get("STAKE_INFO_?") }}
                            </span>
                        </div>
                    </div>
                    <div class="max__stake">
                        <input class="max__input" type="number" v-model="currentStakeAmount" placeholder="0.00" id="number" />
                        <!-- <p class="max__name">0.0</p> -->
                        <button @click="currentStakeAmount = maxStake.toFixed(2)" class="max__btn">{{ lang.get("MAX") }} {{ lang.get("STAKE") }}</button>
                    </div>
                    <p class="balance">{{ lang.get("WALLET_BALANCE") }} {{ tokenBalance }}</p>
                </div>
                <p v-if="showInputError" class="error">{{ errorMessage }}</p>
                <div class="block__second">
                    <div class="second__text">
                        <p class="limit">{{ lang.get("MAX_STAKE_LIMIT") }}</p>
                        <p class="limit__number">
                            {{ numberWithSpaces(Number(pool.totalMaxStake) - (Number(totalPoolStaking) > 0 ? Number(totalPoolStaking) : 0)) }}
                            {{ tokenTicker(pool.name) }}
                        </p>
                    </div>
                    <div v-if="showPoolLimitExceededAlert" class="inform">
                        <i class="i-attention"></i>
                        <div>
                            {{ lang.get("POOL_LIMIT_EXCEED") }}
                            {{ currentStakeAmount }}
                        </div>
                    </div>
                    <div class="second__text">
                        <div class="second__flex">
                            <p class="limit">{{ lang.get("DAYS_LEFT") }}</p>
                            <div class="row__days__text">
                                <i class="i-question-line"></i>
                                <span class="question-line-text">
                                    {{ lang.get("DAYS_LEFT_?") }}
                                </span>
                            </div>
                        </div>
                        <p class="limit__number">{{ poolEndsIn }}</p>
                    </div>
                    <div class="second__text">
                        <p class="limit">{{ lang.get("BASE_APR") }}</p>
                        <p class="limit__number">{{ Number(baseApr) > 0 ? Number(baseApr).toFixed(2) : "0.00" }}%</p>
                    </div>
                    <div v-if="hasReferrerObj[pool.contractId]" class="second__text">
                        <div class="ref-log">
                            <p class="limit">{{ lang.get("BASE_APR") }} +4%</p>
                            <div class="row__days__text">
                                <i class="i-question-line"></i>
                                <span class="question-line-text">
                                    {{ lang.get("BASE_APR_BONUS_?") }}
                                </span>
                            </div>
                        </div>
                        <p class="limit__number">{{ Number(baseApr) > 0 ? (Number(baseApr) + 4).toFixed(2) : "0.00" }}%</p>
                    </div>
                    <div class="second__text">
                        <p class="limit">{{ lang.get("ANNUAL_ROI") }}</p>
                        <div class="divv">
                            <p class="limit__number">$ {{ roi >= 0 ? roi : "N/A" }}</p>
                            <i @click="showCalculator = true" class="i-calculator-line"></i>
                        </div>
                    </div>
                </div>
                <button :disabled="tokensApproved || showInputError" @click="approve()" class="make__stake">{{ lang.get("APPROVE") }}</button>
                <button @click="stake()" class="make__stake" :disabled="!tokensApproved || showInputError">{{ lang.get("NEW_STAKE") }}</button>
            </div>
        </div>
    </div>
</template>
<script>
    import MultiLang from "@/core/multilang";
    import conf from "../../../Config.json";
    import { mapState } from "vuex";
    var Config = conf[window.localStorage.getItem("selectedNetwork")];
    import { ethers } from "ethers";
    import CaclulatorModal from "./CaclulatorModal.vue";

    export default {
        props: [
            "tokenAddress",
            "tokenDecimals",
            "showStakeWindow",
            "tokenBalance",
            "pool",
            "totalPoolStaking",
            "stakeTokenContract",
            "farmTokenContract",
            "tokensTotalStake",
        ],
        data: function () {
            return {
                lang: new MultiLang(this),
                stakeSucceded: false,
                tokensApproved: false,
                validReferrer: false,
                intervalUpdate: null,
                poolData: null,
                currentStakeAmount: "",
                allowance: 0,
                showPoolLimitExceededAlert: false,
                showCalculator: false,
                showInputError: false,
                errorMessage: "",
                roi: 0,
                baseApr: this.realPoolAPYPercentage(this.pool),
            };
        },
        mounted() {
            this.lang.init();
            let _this = this;
            setTimeout(async function init() {
                try {
                    _this.poolData = _this.pool;
                    _this.poolData.poolContractAddress = Config.CONTRACT_VERSION_CONFIG[_this.poolData.contractId].POOL_ADDRESS;
                    if (Number(_this.poolData.poolTotalStake) + Number(_this.stakeAmount) > _this.poolData.totalMaxStake) {
                        _this.currentStakeAmount = Number(_this.poolData.totalMaxStake) - Number(_this.poolData.poolTotalStake);
                        _this.showPoolLimitExceededAlert = true;
                        _this.$emit("poolLimitExceed", _this.currentStakeAmount);
                    }
                    let decimals = _this.poolData.decimals;
                    _this.allowance = Number(
                        ethers.utils.formatUnits(await _this.stakeTokenContract.allowance(_this.currentAddress, _this.poolData.poolContractAddress), decimals)
                    );
                    _this.validReferrer = _this.poolData.referrerStatus;

                    _this.intervalUpdate = setInterval(() => {
                        _this.$forceUpdate();
                    }, 2000); //TODO discuss
                    const number = document.getElementById("number");

                    // Listen for input event on numInput.
                    number.onkeydown = function (e) {
                        if (
                            !(
                                (e.keyCode > 95 && e.keyCode < 106) ||
                                (e.keyCode > 47 && e.keyCode < 58) ||
                                e.keyCode === 8 ||
                                e.keyCode === 37 ||
                                e.keyCode === 39 ||
                                e.keyCode === 188 ||
                                e.keyCode === 190
                            )
                        ) {
                            return false;
                        }
                    };
                } catch (ex) {
                    console.log(ex);
                    setTimeout(init, 300);
                }
            }, 0);
        },
        methods: {
            realPoolAPYPercentage(pool, stakeAmount = 0) {
                if (pool.poolTotalStake === 0) {
                    return "N/A";
                } else {
                    const poolAddress = pool.address.toLowerCase();
                    const poolTotalStake = this.tokensTotalStake[pool.contractId][poolAddress];
                    const resCoef = (pool.maxProfitPercent * pool.coefTokens) / (Number(poolTotalStake) + Number(stakeAmount));
                    return this.$root.core.withoutRound(resCoef) === "0.00" ? "N/A" : resCoef !== NaN ? this.$root.core.withoutRound(resCoef) : "N/A";
                }
            },
            numberWithSpaces(x) {
                return Number(x)
                    .toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, " ");
            },
            tokenTicker(tokenName) {
                return Config.tokenAliases[tokenName];
            },
            checkBalance() {
                this.showInputError = false;
                this.errorMessage = "";

                var numbers = this.currentStakeAmount.toString().split(".");
                var preDecimal = numbers[0];
                var postDecimal = numbers[1];

                if (Number(this.currentStakeAmount) > Number(this.tokenBalance)) {
                    this.showInputError = true;
                    this.errorMessage = "Low balance";
                } else {
                    this.showInputError = false;
                    this.errorMessage = "";
                }

                if (this.pool && Number(this.currentStakeAmount) > this.pool.totalMaxStake - this.totalPoolStaking) {
                    this.showInputError = true;
                    this.errorMessage = "Stake amount exceeds limit";
                }

                if (this.pool && Number(this.currentStakeAmount) < this.pool.userMinStake) {
                    this.showInputError = true;
                    this.errorMessage = `Min amount is ${this.pool.userMinStake}`;
                }
                if (Number(this.currentStakeAmount) > this.pool.userMaxStake) {
                    this.showInputError = true;
                    this.errorMessage = `Max stake amount is ${this.poolData.userMaxStake}`;
                }
                if (postDecimal?.length > 4) {
                    this.showInputError = true;

                    this.errorMessage = "Max decimal limit reached. Allowed decimals count is 4";
                }
            },
            async stake() {
                try {
                    let ref = localStorage.getItem("ref");
                    const totalStake = this.poolData.poolTotalStake;
                    if (Number(this.currentStakeAmount) + Number(totalStake) > this.poolData.totalMaxStake) {
                        this.$emit("poolLimitReached", Number(this.poolData.totalMaxStake) - totalStake);
                        return;
                    }

                    const res = await this.$root.core.estimateGas("stake", this.poolData.contractId, {
                        ref: ref || "",
                        token: this.tokenAddress,
                        amount: this.currentStakeAmount,
                    });
                    const fee = (Number(res) * Config.DEFAULT_GAS_PRICE_GWEI * 10 ** 9) / 10 ** 18;

                    if (fee > this.userCoinBalance) {
                        debugger;
                        this.showInputError = true;
                        this.errorMessage = "Not enough funds to cover the fees";
                        return;
                    }

                    let rawStakeTransaction = await this.$root.core.stake(
                        this.tokenAddress,
                        this.currentStakeAmount,
                        this.pool.decimals,
                        ref,
                        this.poolData.poolVersion,
                        this.poolData.contractId
                    );
                    this.$emit("waitStake");
                    this.$root.core.forceRestart(this.currentBlockchain, this.pool.poolContractAddress);
                    let resultStake = await rawStakeTransaction.wait();
                    if (resultStake.transactionHash) {
                        this.$emit("close");
                        this.$emit("closeStakeWindow");
                        this.$emit("stakeSuccess");
                        setTimeout(() => {
                            this.$emit("close");
                        }, 3000);
                    }
                    this.stakingAmount = 0;
                } catch (error) {
                    console.log(error);
                    this.$emit("showError");
                }
            },
            async approve() {
                try {
                    if (Number(this.currentStakeAmount) === 0) {
                        this.showInputError = true;
                        this.errorMessage = `Min amount is ${this.pool.userMinStake}`;
                        return;
                    }
                    let rawTransaction = await this.$root.core.approve(
                        this.stakeTokenContract,
                        this.currentStakeAmount,
                        this.pool.decimals,
                        this.poolData.poolVersion,
                        this.poolData.contractId
                    );
                    this.$emit("waitApprove");
                    let resultAllowance = await rawTransaction.wait();
                    if (resultAllowance.transactionHash) {
                        this.$emit("close");
                        this.$emit("approveSuccess");
                        setTimeout(() => this.$emit("close"), 3000);
                        this.tokensApproved = true;
                    }
                } catch (error) {
                    console.log(error);
                    this.$emit("close");
                    this.$emit("showError");
                }
            },
            getTokenPrice(tokenAddress) {
                return this.currentRate && this.currentRate[tokenAddress.toLowerCase()] ? this.currentRate[tokenAddress.toLowerCase()] : 0.1; //TODO get price for correct token address
            },
        },
        computed: {
            ...mapState(["currentAddress", "farmTokens", "currentBlockchain", "currentRate", "hasReferrerObj", "userCoinBalance"]),
            poolEndsIn() {
                //TODO: change when multiple contracts added
                const currentDate = Math.floor(new Date().getTime() / 1000);
                const daysPassed = Math.floor((currentDate - Config.CONTRACT_VERSION_CONFIG[this.pool.contractId].POOL_START_TIME) / 3600 / 24);
                return Config.CONTRACT_VERSION_CONFIG[this.pool.contractId].STAKE_DAYS - daysPassed;
            },
            maxStake() {
                if (this.poolData && this.tokenBalance && this.totalPoolStaking) {
                    return Math.min(
                        Number(this.poolData.totalMaxStake) - Number(this.totalPoolStaking),
                        Number(this.tokenBalance),
                        Number(this.poolData.userMaxStake)
                    );
                }
                return 0;
            },
        },

        components: { CaclulatorModal },
        watch: {
            currentStakeAmount: function (newVal) {
                this.baseApr = this.realPoolAPYPercentage(this.pool, Number(newVal));
                this.$emit("stakeAmount", newVal);
                if (newVal > 0 && newVal <= this.allowance) {
                    this.tokensApproved = true;
                } else {
                    this.tokensApproved = false;
                }
                let amountInUsd = 0;
                for (let rewardToken of this.pool.farmTokensList) {
                    let amountCrypto = (Number(newVal) * Number(this.realPoolAPYPercentage(this.pool, newVal))) / 100;
                    let tokenPrice = Number(this.getTokenPrice(rewardToken.address));
                    amountInUsd += tokenPrice * amountCrypto;
                }

                this.checkBalance();

                this.roi = amountInUsd.toFixed(2);
            },
        },
    };
</script>
