<template>
    <div>
        <caclulator-modal
            v-if="showCalculator && clickedPool !== null"
            :pool="clickedPool"
            :tokensTotalStake="tokensTotalStake"
            @close="(showCalculator = false), (clickedPool = null), (tokensTotalStake = {})"
        />
        <div v-for="(pool, index) in poolsToShow" :key="pool.chainId + pool.id">
            <!-- <CaclulatorModal :pool="pool" :baseApr="poolAPYPercentageNoWallet(pool)" @close="showCalculator = false" :showCalculator="showCalculator" /> -->
            <div class="data__row margin border" :class="{ active_row: ifShowPoolDetails(index) === true }">
                <div class="wrapper border">
                    <div class="pool__token-name">
                        <span class="row__one__ball" :class="pool.name"></span>
                        <p class="text__name__token">{{ tokenTicker(pool, pool.name) }} {{ pool.contractId.replace("N", "№") }}</p>
                    </div>

                    <div class="full__row">
                        <div class="row__five pool__stats">
                            <p class="mobile__row">Reward<i class="i-question-line"> </i></p>
                            <div class="balls">
                                <span
                                    v-for="farmToken in getFarmTokensForPoolNoWallet(pool)"
                                    :key="farmToken.address"
                                    class="row__one__ball"
                                    :class="farmToken.name"
                                >
                                </span>
                            </div>
                        </div>

                        <div class="row__one-pool">
                            <p class="mobile__row">APR<i class="i-question-line"> </i></p>
                            <p class="desktop__row">
                                {{ poolAPYPercentageNoWallet(pool) }}% <i @click="prepareCalculator(pool)" class="i-calculator-line"></i>
                            </p>
                        </div>
                        <div class="row__two-pool">
                            <p class="mobile__row">Days staked<i class="i-question-line"> </i></p>
                            <p class="desktop__row">{{ maxStakeDays(pool) - poolEndsIn(pool) }} / {{ maxStakeDays(pool) }} days</p>
                        </div>
                        <div class="row__there">
                            <p class="mobile__row">ends with<i class="i-question-line"> </i></p>
                            <p class="first__nav__title">
                                {{ tokensInPool(pool)[0] }} <span class="two__zero">/ {{ tokensInPool(pool)[1] }} {{ tokenTicker(pool, pool.name) }}</span>
                            </p>
                        </div>
                        <div class="row__one-chain">
                            <p class="mobile__row">Blockchain<i class="i-question-line"> </i></p>
                            <span
                                class="row__one__ball"
                                :class="{
                                    'network-binance': pool.chainId === 56,
                                    'network-ethereum': pool.chainId === 1,
                                    'network-harmony': pool.chainId === 1666600000,
                                }"
                            ></span>
                        </div>
                    </div>
                    <div class="test">
                        <button v-if="currentAddress === '0x0000000000000000000000000000000000000000'" @click="$emit('connectWallet')" class="for__hide_one">
                            Connect Wallet
                        </button>
                        <button
                            v-else-if="currentAddress !== '0x0000000000000000000000000000000000000000' && unsupportedChainId"
                            @click="changeNetwork(pool.blockchainSymbol)"
                            class="for__hide_one change"
                        >
                            Change Network
                        </button>
                        <div @click.stop="showPoolDetails(index)" class="left__line">
                            <i class="i-arrow-drop-down-line line"></i>
                        </div>
                        <button @click.stop="showPoolDetails(index)" class="for__more">{{ ifShowPoolDetails(index) === false ? "More" : "Hide" }}</button>
                    </div>
                </div>
                <div v-if="ifShowPoolDetails(index)" class="active__block">
                    <div class="under__data__row">
                        <div class="second__second__inner" :class="{ 'reward-colum': getFarmTokensForPoolNoWallet(pool).length === 1 }">
                            <div v-if="pool.name.includes('-lp')" class="second__second__item">
                                <div class="plus">
                                    <p class="plus__text">LP token info</p>
                                    <div class="row__days__text">
                                        <i class="i-question-line"></i>
                                        <span class="question-line-text">
                                            {{ lang.get("LP_TOKEN_INFO") }}
                                        </span>
                                    </div>
                                </div>
                                <div class="inform__plus step-block">
                                    <div class="token">
                                        <span class="row__one__ball" :class="pool.name"></span>
                                        <p class="token__text">{{ tokenTicker(pool, pool.name) }}</p>
                                    </div>
                                    <div class="info__fourth">
                                        <div class="info__fourth">
                                            <a :href="`${getBlockchainExplorer(pool.chainId)}${pool.address}`" target="_blank" class="tooltip">
                                                <i class="i-file-list-2-line"></i>
                                                <a class="tooltiptext">View Contract</a>
                                            </a>
                                            <a
                                                href="https://bringfinance.medium.com/staking-on-bring-2-0-how-it-works-74bc4d89762a"
                                                target="_blank"
                                                class="tooltip"
                                            >
                                                <i class="i-settings-5-line"></i>
                                                <a
                                                    href="https://bringfinance.medium.com/staking-on-bring-2-0-how-it-works-74bc4d89762a"
                                                    class="tooltiptext"
                                                    target="_blank"
                                                    >How It Works</a
                                                >
                                            </a>
                                            <a
                                                href="https://bringfinance.medium.com/creating-liquidity-on-pancakeswap-brng-bnb-8a271ff0340b"
                                                target="_blank"
                                                class="tooltip"
                                            >
                                                <i class="i-error-warning-line"></i>
                                                <a href="" class="tooltiptext">How to Stake LP</a>
                                            </a>
                                            <a :href="`${getBlockchainDex(pool.chainId)}${pool.pairAddress}`" class="tooltip pan-cake" target="_blank">
                                                <a class="tooltiptext">Get LP-token</a>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-else class="second__second__item">
                                <div class="plus">
                                    <p class="plus__text">Token info</p>
                                    <div class="row__days__text">
                                        <i class="i-question-line"></i>
                                        <span class="question-line-text">
                                            {{ lang.get("LP_TOKEN_INFO") }}
                                        </span>
                                    </div>
                                </div>
                                <div class="inform__plus step-block">
                                    <div class="token">
                                        <span class="row__one__ball" :class="pool.name"></span>
                                        <p class="token__text">{{ tokenTicker(pool, pool.name) }}</p>
                                    </div>
                                    <div class="info__fourth">
                                        <a :href="`${getBlockchainExplorer(pool.chainId)}${pool.address}`" target="_blank" class="tooltip">
                                            <i class="i-file-list-2-line"></i>
                                            <a class="tooltiptext">View Contract</a>
                                        </a>
                                        <a
                                            href="https://bringfinance.medium.com/staking-on-bring-2-0-how-it-works-74bc4d89762a"
                                            target="_blank"
                                            class="tooltip"
                                        >
                                            <i class="i-settings-5-line"></i>
                                            <a
                                                href="https://bringfinance.medium.com/staking-on-bring-2-0-how-it-works-74bc4d89762a"
                                                class="tooltiptext"
                                                target="_blank"
                                                >How It Works</a
                                            >
                                        </a>
                                        <a
                                            href="https://bringfinance.medium.com/creating-liquidity-on-pancakeswap-brng-bnb-8a271ff0340b"
                                            target="_blank"
                                            class="tooltip"
                                        >
                                            <i class="i-error-warning-line"></i>
                                            <a href="" class="tooltiptext">How to Stake LP</a>
                                        </a>
                                        <a href="https://bridge.bring.finance/" class="tooltip bridge">
                                            <a class="tooltiptext">Bridge Token</a>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="second__second__item width">
                                <div class="plus">
                                    <p class="plus__text">Reward token info</p>
                                    <div class="row__days__text">
                                        <i class="i-question-line"></i>
                                        <span class="question-line-text">
                                            {{ lang.get("REWARD_TOKEN_INFO") }}
                                        </span>
                                    </div>
                                </div>
                                <div class="item__flex">
                                    <div class="all__wallet" v-for="farmToken in getFarmTokensForPoolNoWallet(pool)" :key="farmToken.address">
                                        <div class="token">
                                            <span class="row__one__ball" :class="farmToken.name"></span>
                                            <p class="token__text">{{ tokenTicker(pool, farmToken.name) }}</p>
                                        </div>
                                        <div class="info__fourth">
                                            <a :href="`${getBlockchainExplorer(pool.chainId)}${farmToken.address}`" target="_blank" class="tooltip">
                                                <i class="i-file-list-2-line"></i>
                                                <a class="tooltiptext">View Contract</a>
                                            </a>
                                            <a href="https://www.coingecko.com/en/coins/bring-finance" target="_blank" class="tooltip draco">
                                                <a class="tooltiptext">CoinGecko</a>
                                            </a>
                                            <a @click="addTokenToMetaMask(farmToken)" class="tooltip fox">
                                                <a class="tooltiptext">Add to Metamask</a>
                                            </a>
                                            <a href="https://bridge.bring.finance/" target="_blank" class="tooltip bridge">
                                                <a class="tooltiptext">Bridge Token</a>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState } from "vuex";
    import MultiLang from "@/core/multilang";
    import conf from "../../Config.json";
    import CaclulatorModal from "./modalWindows/CaclulatorModal.vue";
    var Config = conf[window.localStorage.getItem("selectedNetwork")] || conf[conf.PRIMARY_BLOCKCHAIN.chainId];
    export default {
        name: "PoolStats",
        props: ["contractType", "unsupportedChainId", "sortBy", "searchTokenName", "isLive"],
        data() {
            return {
                lang: new MultiLang(this),
                showCalculator: false,
                finishedPools: [],
                showDetails: [],
                clickedPool: null,
                tokensTotalStake: {},
            };
        },
        computed: {
            ...mapState(["allContractsData", "currentAddress", "currentBlockchain"]),

            poolsToShow() {
                const allChains = conf.SUPPORTED_BLOCKCHAINS;
                const allPools = [];
                for (let chain of allChains) {
                    allPools.push(
                        conf[chain].stakeTokens.map((el) => {
                            const contractAddressDB = chain + "_" + conf[chain].CONTRACT_VERSION_CONFIG[el.contractId].POOL_ADDRESS.toLowerCase();
                            const POOL_END_TIME = conf[chain].CONTRACT_VERSION_CONFIG[el.contractId].POOL_END_TIME;
                            const POOL_START_TIME = conf[chain].CONTRACT_VERSION_CONFIG[el.contractId].POOL_START_TIME;
                            return {
                                ...el,
                                chainId: chain,
                                blockchainSymbol: chain === 56 ? "BSC" : chain === 1 ? "ETH" : "HARM", // todo support more chains
                                contractAddressDB,
                                POOL_END_TIME,
                                POOL_START_TIME,
                            };
                        })
                    );
                }
                const flattenPools = allPools.flat();
                const currentTime = new Date().getTime() / 1000;
                let reversedArray;
                if (this.searchTokenName === "") {
                    reversedArray = flattenPools;
                } else {
                    reversedArray = flattenPools.filter((token) => token.tag.includes(this.searchTokenName.toUpperCase()));
                }
                if (this.isLive === true) {
                    reversedArray = reversedArray.filter((el) => el.POOL_END_TIME > currentTime);
                }
                if (this.sortBy === "") {
                    if (this.isLive === true) {
                        if (this.contractType === "all") {
                            return reversedArray.filter((el) => el.POOL_END_TIME > currentTime).sort((a, b) => b.id - a.id);
                        } else {
                            const reversedAndFiltered = reversedArray
                                .filter((el) => el.contractType === this.contractType && el.POOL_END_TIME > currentTime)
                                .sort((a, b) => b.id - a.id);
                            return reversedAndFiltered;
                        }
                    } else {
                        this.finishedPools = reversedArray.filter((el) => el.POOL_END_TIME < currentTime);
                        if (this.contractType === "all") {
                            return this.finishedPools.sort((a, b) => b.id - a.id);
                        } else {
                            const reversedAndFiltered = this.finishedPools.filter((el) => el.contractType === this.contractType).sort((a, b) => b.id - a.id);
                            return reversedAndFiltered;
                        }
                    }
                } else if (this.sortBy === "apr") {
                    if (this.contractType === "all") {
                        reversedArray.sort((a, b) => {
                            const contractDataObjA = this.allContractsData.find(
                                (el) => el.contract_identifier.toLowerCase() === a.contractAddressDB.toLowerCase()
                            );
                            const contractDataObjB = this.allContractsData.find(
                                (el) => el.contract_identifier.toLowerCase() === b.contractAddressDB.toLowerCase()
                            );
                            const totalStakedA = contractDataObjA.data_obj.totalStaked[a.address.toLowerCase()];
                            const totalStakedB = contractDataObjB.data_obj.totalStaked[b.address.toLowerCase()];
                            const aValue = (a.maxProfitPercent * a.coefTokens) / totalStakedA;
                            const bValue = (b.maxProfitPercent * b.coefTokens) / totalStakedB;
                            return bValue - aValue;
                        });
                    } else {
                        reversedArray = reversedArray
                            .filter((el) => el.contractType === this.contractType)
                            .sort((a, b) => {
                                const contractDataObjA = this.allContractsData.find(
                                    (el) => el.contract_identifier.toLowerCase() === a.contractAddressDB.toLowerCase()
                                );
                                const contractDataObjB = this.allContractsData.find(
                                    (el) => el.contract_identifier.toLowerCase() === b.contractAddressDB.toLowerCase()
                                );
                                const totalStakedA = contractDataObjA.data_obj.totalStaked[a.address.toLowerCase()];
                                const totalStakedB = contractDataObjB.data_obj.totalStaked[b.address.toLowerCase()];
                                const aValue = (a.maxProfitPercent * a.coefTokens) / totalStakedA;
                                const bValue = (b.maxProfitPercent * b.coefTokens) / totalStakedB;
                                return bValue - aValue;
                            });
                    }

                    return reversedArray;
                } else if (this.sortBy === "staked") {
                    if (this.contractType === "all") {
                        reversedArray.sort((a, b) => {
                            const POOL_START_TIME_A = a.POOL_START_TIME;
                            const POOL_START_TIME_B = b.POOL_START_TIME;
                            return POOL_START_TIME_B - POOL_START_TIME_A;
                        });
                    } else {
                        reversedArray = reversedArray
                            .filter((el) => el.contractType === this.contractType)
                            .sort((a, b) => {
                                const POOL_START_TIME_A = a.POOL_START_TIME;
                                const POOL_START_TIME_B = b.POOL_START_TIME;
                                return POOL_START_TIME_B - POOL_START_TIME_A;
                            });
                    }
                    return reversedArray;
                } else if (this.sortBy === "completion") {
                    if (this.contractType === "all") {
                        reversedArray.sort((a, b) => {
                            const contractDataObjA = this.allContractsData.find(
                                (el) => el.contract_identifier.toLowerCase() === a.contractAddressDB.toLowerCase()
                            );
                            const contractDataObjB = this.allContractsData.find(
                                (el) => el.contract_identifier.toLowerCase() === b.contractAddressDB.toLowerCase()
                            );
                            const totalStakedA = contractDataObjA.data_obj.totalStaked[a.address.toLowerCase()];
                            const totalStakedB = contractDataObjB.data_obj.totalStaked[b.address.toLowerCase()];
                            const maxStakeA = a.totalMaxStake;
                            const maxStakeB = b.totalMaxStake;
                            const completionPercA = (totalStakedA / maxStakeA) * 100;
                            const completionPercB = (totalStakedB / maxStakeB) * 100;
                            return completionPercA - completionPercB;
                        });
                    } else {
                        reversedArray = reversedArray
                            .filter((el) => el.contractType === this.contractType)
                            .sort((a, b) => {
                                const contractDataObjA = this.allContractsData.find(
                                    (el) => el.contract_identifier.toLowerCase() === a.contractAddressDB.toLowerCase()
                                );
                                const contractDataObjB = this.allContractsData.find(
                                    (el) => el.contract_identifier.toLowerCase() === b.contractAddressDB.toLowerCase()
                                );
                                const totalStakedA = contractDataObjA.data_obj.totalStaked[a.address.toLowerCase()];
                                const totalStakedB = contractDataObjB.data_obj.totalStaked[b.address.toLowerCase()];
                                const maxStakeA = a.totalMaxStake;
                                const maxStakeB = b.totalMaxStake;
                                const completionPercA = (totalStakedA / maxStakeA) * 100;
                                const completionPercB = (totalStakedB / maxStakeB) * 100;
                                return completionPercA - completionPercB;
                            });
                    }
                    return reversedArray;
                } else if (this.sortBy === "alphabet") {
                    if (this.contractType === "all") {
                        reversedArray.sort((a, b) => a.tag.localeCompare(b.tag));
                    } else {
                        reversedArray = reversedArray.filter((el) => el.contractType === this.contractType).sort((a, b) => a.tag.localeCompare(b.tag));
                    }
                    return reversedArray;
                }
            },
        },
        methods: {
            getBlockchainDex(chainId) {
                if (Number(chainId) === 56) {
                    return `https://pancakeswap.finance/add/BNB/`;
                }
            },
            prepareCalculator(pool) {
                this.showCalculator = false;
                this.clickedPool = null;
                this.tokensTotalStake = {};

                this.tokensTotalStake[pool.contractId] = {};
                this.tokensTotalStake[pool.contractId][pool.address.toLowerCase()] = this.allContractsData.find(
                    (el) => el.contract_identifier.toLowerCase() === pool.contractAddressDB.toLowerCase()
                ).data_obj.totalStaked[pool.address.toLowerCase()];

                this.clickedPool = pool;
                this.showCalculator = true;
            },
            showPoolDetails(index) {
                this.$set(this.showDetails, index, !this.showDetails[index]);
            },
            ifShowPoolDetails(index) {
                return this.showDetails[index];
            },
            poolEndsIn(pool) {
                const currentDate = Math.floor(new Date().getTime() / 1000);
                const daysPassed = Math.floor((currentDate - conf[pool.chainId].CONTRACT_VERSION_CONFIG[pool.contractId].POOL_START_TIME) / 3600 / 24);
                return conf[pool.chainId].CONTRACT_VERSION_CONFIG[pool.contractId].STAKE_DAYS - daysPassed > 0
                    ? conf[pool.chainId].CONTRACT_VERSION_CONFIG[pool.contractId].STAKE_DAYS - daysPassed
                    : 0;
            },
            maxStakeDays(pool) {
                return conf[pool.chainId].CONTRACT_VERSION_CONFIG[pool.contractId].STAKE_DAYS;
            },
            getPoolStrengthNoWallet(pool) {
                const poolAddress = pool.address.toLowerCase();
                const totalMaxStake = pool.totalMaxStake;
                const contractDataObj = this.allContractsData.find((el) => el.contract_identifier.toLowerCase() === pool.contractAddressDB.toLowerCase());
                const res =
                    (contractDataObj.data_obj.totalStaked[poolAddress] / totalMaxStake) * 100 > 100
                        ? 100
                        : (contractDataObj.data_obj.totalStaked[poolAddress] / totalMaxStake) * 100;
                return this.$root.core.withoutRound(res);
            },
            tokensInPool(pool) {
                if (this.allContractsData.length > 0) {
                    const poolAddress = pool.address.toLowerCase();
                    const totalMaxStake = pool.totalMaxStake;
                    const contractDataObj = this.allContractsData.find((el) => el.contract_identifier.toLowerCase() === pool.contractAddressDB.toLowerCase());
                    return [Number(contractDataObj.data_obj.totalStaked[poolAddress]).toFixed(0), totalMaxStake];
                }
                return [0, pool.totalMaxStake];
            },
            poolAPYPercentageNoWallet(pool) {
                if (this.allContractsData.length > 0) {
                    const poolAddress = pool.address.toLowerCase();
                    const coefTokens = pool.coefTokens;
                    const contractDataObj = this.allContractsData.find((el) => el.contract_identifier.toLowerCase() === pool.contractAddressDB.toLowerCase());
                    const poolTotalStake = contractDataObj.data_obj.totalStaked[poolAddress];
                    const resCoef = (100 * coefTokens) / poolTotalStake;
                    return this.$root.core.withoutRound(resCoef) === "0.00" ? "N/A" : this.$root.core.withoutRound(resCoef);
                }
                return "0.00";
            },
            getFarmTokensForPoolNoWallet(pool) {
                return pool.farmTokensList;
            },
            tokenTicker(pool, tokenName) {
                return conf[pool.chainId].tokenAliases[tokenName];
            },
            async addTokenToMetaMask(token) {
                if (window.ethereum && window.localStorage.getItem("selectedWallet") === "metamask") {
                    try {
                        // wasAdded is a boolean. Like any RPC method, an error may be thrown
                        await window.ethereum.request({
                            method: "wallet_watchAsset",
                            params: {
                                type: "ERC20",
                                options: {
                                    address: token.address,
                                    symbol: token.tag,
                                    decimals: token.decimals || 18, // The number of decimals in the token
                                    image: `${conf.REF_BASE}tokens/${token.tag.toLowerCase()}.png`, // A string url of the token logo
                                },
                            },
                        });
                    } catch (error) {
                        console.log(error);
                    }
                } else if (window.localStorage.getItem("selectedWallet") === "walletconnect") {
                    try {
                        await this.$root.core.provider.provider.request({
                            method: "wallet_watchAsset",
                            params: {
                                type: "ERC20",
                                options: {
                                    address: token.address,
                                    symbol: token.tag,
                                    decimals: token.decimals || 18, // The number of decimals in the token
                                    image: `${conf.REF_BASE}tokens/${token.tag.toLowerCase()}.png`, // A string url of the token logo
                                },
                            },
                        });
                    } catch (error) {
                        console.log(error);
                    }
                }
            },
            async changeNetwork(symbol) {
                await this.$root.core.changeNetwork(this, symbol.toLowerCase());
            },
            getBlockchainExplorer(currentBlockchain) {
                return currentBlockchain === 56
                    ? `https://bscscan.com/address/`
                    : currentBlockchain === 1666600000
                    ? `https://explorer.harmony.one/address/`
                    : currentBlockchain === 1
                    ? `https://etherscan.io/address/`
                    : "";
            },
        },
        mounted() {
            this.lang.init();
            let _this = this;
            setTimeout(function init() {
                if (!_this.poolsToShow || !_this.poolsToShow.length) {
                    setTimeout(init, 300);
                    return;
                } else {
                    _this.showDetails = new Array(_this.poolsToShow.length).fill(false, 0, _this.poolsToShow.length);
                }
            }, 300);
        },
        components: { CaclulatorModal },
    };
</script>
