<template>
    <div>
        <stake-in-pool-modal
            v-if="showStakeWindow"
            :tokenAddress="pool.address"
            :tokenDecimals="pool.decimals"
            :showStakeWindow="showStakeWindow"
            :tokenBalance="stakeTokenBalance"
            :totalPoolStaking="totalPoolStaking"
            :pool="pool"
            :poolEndsIn="poolEndsIn(pool)"
            :stakeTokenContract="stakeTokenContract"
            :farmTokenContract="farmTokenContract"
            :tokensTotalStake="tokensTotalStake"
            @poolLimitExceed="stakeAmount = $event"
            @poolLimitReached="(showPoolLimitReached = true), (stakeAmount = $event)"
            @waitStake="showPendingStake = true"
            @waitApprove="showPendingApprove = true"
            @showError="showError = true"
            @close="(showPendingStake = false), (showPendingApprove = false), (showSuccessApprove = false), (showSuccessStake = false)"
            @approveSuccess="showSuccessApprove = true"
            @stakeSuccess="(showSuccessStake = true), redirectToStake()"
            @closeStakeWindow="showStakeWindow = false"
            @stakeAmount="stakeAmount = $event"
        />
        <transaction-processing
            :stakeAmount="stakeAmount"
            :tokenName="pool.name"
            :showPendingStake="showPendingStake"
            :showPendingApprove="showPendingApprove"
            :showSuccessStake="showSuccessStake"
            :showSuccessApprove="showSuccessApprove"
            :showError="showError"
            :showPoolLimitReached="showPoolLimitReached"
            @close="
                (showPendingStake = false),
                    (showSuccessStake = false),
                    (showPendingApprove = false),
                    (showSuccessApprove = false),
                    (showError = false),
                    (showPoolLimitReached = false)
            "
        />

        <calculator-modal
            v-if="showCalculator"
            :pool="pool"
            :tokensTotalStake="tokensTotalStakeForPool"
            @close="(showCalculator = false), (tokensTotalStakeForPool = {})"
        />
        <div class="data__row margin border" :class="{ active_row: showPoolDetails }">
            <div class="wrapper border" @click.stop="showPoolDetails = !showPoolDetails">
                <div class="pool__token-name">
                    <span class="row__one__ball" :class="pool.name"></span>
                    <p class="text__name__token">{{ tokenTicker(pool, pool.name) }} {{ pool.contractId.replace("N", "№") }}</p>
                </div>
                <div class="full__row">
                    <div class="row__five">
                        <div class="row__one__inner">
                            <div class="mobile__row__second">
                                <p class="mobile__row">Reward</p>
                                <div @click.stop="showPoolDetails = showPoolDetails" class="row__days__text">
                                    <i class="i-question-line"></i>
                                    <span class="question-line-text">
                                        {{ lang.get("REWARD_?") }}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="balls">
                            <span v-for="farmToken in getFarmTokens(pool)" :key="farmToken.address" class="row__one__ball" :class="farmToken.name"> </span>
                        </div>
                    </div>

                    <div class="row__one-pool">
                        <div class="row__one__inner">
                            <p class="mobile__row">APR</p>
                            <div @click.stop="showPoolDetails = showPoolDetails" class="row__days__text">
                                <i class="i-question-line"></i>
                                <span class="question-line-text">
                                    {{ lang.get("APR_INFO_?") }}
                                </span>
                            </div>
                        </div>
                        <p class="desktop__row">{{ poolAPYPercentage(pool) }}% <i @click.stop="prepareCalculator(pool)" class="i-calculator-line"></i></p>
                    </div>
                    <div class="row__two-pool">
                        <div class="row__one__inner">
                            <p class="mobile__row">Days staked</p>
                            <div @click.stop="showPoolDetails = showPoolDetails" class="row__days__text">
                                <i class="i-question-line"></i>
                                <span class="question-line-text">
                                    {{ lang.get("DAYS_STAKED_?") }}
                                </span>
                            </div>
                        </div>
                        <p class="desktop__row">{{ maxStakeDays(pool) - (poolEndsIn(pool) > 0 ? poolEndsIn(pool) : 0) }} / {{ maxStakeDays(pool) }} days</p>
                    </div>
                    <div class="row__there">
                        <div class="row__one__inner">
                            <p class="mobile__row">Pool Completion</p>
                            <div @click.stop="showPoolDetails = showPoolDetails" class="row__days__text">
                                <i class="i-question-line"></i>
                                <span class="question-line-text">
                                    {{ lang.get("ENDS_WITH_?") }}
                                </span>
                            </div>
                        </div>
                        <p class="first__nav__title">
                            {{ tokensInPool(pool)[0] }}<span class="two__zero">/ {{ tokensInPool(pool)[1] }} {{ tokenTicker(pool, pool.name) }}</span>
                        </p>
                    </div>
                    <div v-if="pool.chainId" class="row__one-chain">
                        <div class="row__one__inner">
                            <p class="mobile__row">Blockchain</p>
                            <div @click.stop="showPoolDetails = showPoolDetails" class="row__days__text">
                                <i class="i-question-line"></i>
                                <span class="question-line-text">
                                    {{ lang.get("APR_INFO_?") }}
                                </span>
                            </div>
                        </div>
                        <span
                            class="row__one__ball"
                            :class="{
                                'network-binance': pool.chainId === 56,
                                'network-ethereum': pool.chainId === 1,
                                'network-harmony': pool.chainId === 1666600000,
                            }"
                        ></span>
                    </div>
                    <div v-else class="row__one-chain">
                        <div class="row__one__inner">
                            <p class="mobile__row">Blockchain</p>
                            <div @click.stop="showPoolDetails = showPoolDetails" class="row__days__text">
                                <i class="i-question-line"></i>
                                <span class="question-line-text">
                                    {{ lang.get("APR_INFO_?") }}
                                </span>
                            </div>
                        </div>
                        <span
                            class="row__one__ball"
                            :class="{
                                'network-binance': currentBlockchain === 56,
                                'network-ethereum': currentBlockchain === 1,
                                'network-harmony': currentBlockchain === 1666600000,
                            }"
                        ></span>
                    </div>
                </div>
                <div class="test">
                    <button
                        v-if="isLive === true && (!pool.chainId || pool.chainId === currentBlockchain)"
                        @click.stop="showStakeWindow = true"
                        class="for__hide_one"
                    >
                        Stake
                    </button>
                    <button
                        v-else-if="isLive === true && pool.chainId && pool.chainId !== currentBlockchain"
                        @click.stop="changeNetwork(pool.blockchainSymbol)"
                        class="for__hide_one change"
                    >
                        Change Network
                    </button>
                    <div class="left__line">
                        <i @click.stop="showPoolDetails = !showPoolDetails" class="i-arrow-drop-down-line line"></i>
                    </div>
                    <button @click.stop="showPoolDetails = !showPoolDetails" class="for__more">{{ showPoolDetails === false ? "More" : "Hide" }}</button>
                </div>
            </div>
            <div v-if="showPoolDetails" class="active__block">
                <div class="under__data__row">
                    <div class="second__second__inner" :class="{ 'reward-colum': getFarmTokens(pool).length === 1 }">
                        <div v-if="pool.name.includes('-lp')" class="second__second__item">
                            <div class="plus">
                                <p class="plus__text">LP token info</p>
                                <div class="row__days__text">
                                    <i class="i-question-line"></i>
                                    <span class="question-line-text">
                                        {{ lang.get("LP_TOKEN_INFO") }}
                                    </span>
                                </div>
                            </div>

                            <div class="inform__plus step-block">
                                <div class="token">
                                    <span class="row__one__ball" :class="pool.name"></span>
                                    <p class="token__text">{{ tokenTicker(pool, pool.name) }}</p>
                                </div>
                                <div class="info__fourth">
                                    <a
                                        :href="`${getBlockchainExplorer(pool.chainId ? pool.chainId : currentBlockchain)}${pool.address}`"
                                        target="_blank"
                                        class="tooltip"
                                    >
                                        <i class="i-file-list-2-line"></i>
                                        <a class="tooltiptext">View Contract</a>
                                    </a>
                                    <a href="https://bringfinance.medium.com/staking-on-bring-2-0-how-it-works-74bc4d89762a" target="_blank" class="tooltip">
                                        <i class="i-settings-5-line"></i>
                                        <a href="https://bringfinance.medium.com/staking-on-bring-2-0-how-it-works-74bc4d89762a" class="tooltiptext"
                                            >How It Works</a
                                        >
                                    </a>
                                    <a
                                        href="https://bringfinance.medium.com/creating-liquidity-on-pancakeswap-brng-bnb-8a271ff0340b"
                                        target="_blank"
                                        class="tooltip"
                                    >
                                        <i class="i-error-warning-line"></i>
                                        <a href="" class="tooltiptext">How to Stake LP</a>
                                    </a>
                                    <a
                                        :href="`${getBlockchainDex(pool.chainId ? pool.chainId : currentBlockchain)}${pool.pairAddress}`"
                                        class="tooltip pan-cake"
                                        target="_blank"
                                    >
                                        <a class="tooltiptext">Get LP-token</a>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div v-else class="second__second__item item-second">
                            <div class="plus">
                                <p class="plus__text">Token info</p>
                                <div class="row__days__text">
                                    <i class="i-question-line"></i>
                                    <span class="question-line-text">
                                        {{ lang.get("LP_TOKEN_INFO") }}
                                    </span>
                                </div>
                            </div>
                            <div class="inform__plus step-block">
                                <div class="token">
                                    <span class="row__one__ball" :class="pool.name"></span>
                                    <p class="token__text">{{ tokenTicker(pool, pool.name) }}</p>
                                </div>
                                <div class="info__fourth">
                                    <a
                                        :href="`${getBlockchainExplorer(pool.chainId ? pool.chainId : currentBlockchain)}${pool.address}`"
                                        target="_blank"
                                        class="tooltip"
                                    >
                                        <i class="i-file-list-2-line"></i>
                                        <a class="tooltiptext">View Contract</a>
                                    </a>
                                    <a href="https://bringfinance.medium.com/staking-on-bring-2-0-how-it-works-74bc4d89762a" class="tooltip">
                                        <i class="i-settings-5-line"></i>
                                        <a href="https://bringfinance.medium.com/staking-on-bring-2-0-how-it-works-74bc4d89762a" class="tooltiptext"
                                            >How It Works</a
                                        >
                                    </a>
                                    <a
                                        href="https://bringfinance.medium.com/creating-liquidity-on-pancakeswap-brng-bnb-8a271ff0340b"
                                        target="_blank"
                                        class="tooltip"
                                    >
                                        <i class="i-error-warning-line"></i>
                                        <a href="https://bringfinance.medium.com/creating-liquidity-on-pancakeswap-brng-bnb-8a271ff0340b" class="tooltiptext"
                                            >Bridging Details</a
                                        >
                                    </a>

                                    <a href="https://bridge.bring.finance" target="_blank" class="tooltip bridge">
                                        <a class="tooltiptext">Bridge Token</a>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="second__second__item width">
                            <div class="plus">
                                <p class="plus__text">Reward token info</p>
                                <div class="row__days__text">
                                    <i class="i-question-line"></i>
                                    <span class="question-line-text">
                                        {{ lang.get("REWARD_TOKEN_INFO") }}
                                    </span>
                                </div>
                            </div>
                            <div class="item__flex">
                                <div class="all__wallet" v-for="farmToken in getFarmTokens(pool)" :key="farmToken.address">
                                    <div class="token">
                                        <span class="row__one__ball" :class="farmToken.name"></span>
                                        <p class="token__text">{{ tokenTicker(pool, farmToken.name) }}</p>
                                    </div>
                                    <div class="info__fourth">
                                        <a
                                            :href="`${getBlockchainExplorer(pool.chainId ? pool.chainId : currentBlockchain)}${farmToken.address}`"
                                            target="_blank"
                                            class="tooltip"
                                        >
                                            <i class="i-file-list-2-line"></i>
                                            <a class="tooltiptext">View Contract</a>
                                        </a>
                                        <a href="https://www.coingecko.com/en/coins/bring-finance" target="_blank" class="tooltip draco">
                                            <a class="tooltiptext">CoinGecko</a>
                                        </a>
                                        <a @click.prevent="addTokenToMetaMask(farmToken)" class="tooltip fox">
                                            <a href="" class="tooltiptext">Add to Metamask</a>
                                        </a>
                                        <a href="https://bridge.bring.finance/" target="_blank" class="tooltip bridge">
                                            <a class="tooltiptext">Bridge Token</a>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState } from "vuex";
    import conf from "../../Config.json";
    var Config = conf[window.localStorage.getItem("selectedNetwork")] || conf[conf.PRIMARY_BLOCKCHAIN.chainId];
    import StakeInPoolModal from "./modalWindows/StakeInPoolModal.vue";
    import CalculatorModal from "./modalWindows/CaclulatorModal.vue";
    import TransactionProcessing from "./modalWindows/TransactionProcessing.vue";
    import MultiLang from "@/core/multilang";
    import { tokenAbi } from "../../src/abi/tokenAbi";
    import { ethers } from "ethers";

    export default {
        components: {
            StakeInPoolModal,
            TransactionProcessing,
            CalculatorModal,
        },
        props: ["pool", "isLive", "stakeTokens"],
        data: function () {
            return {
                stakeAmount: 0,
                lang: new MultiLang(this),
                showPoolDetails: false,
                showStakeWindow: false,
                showPendingStake: false,
                showPendingApprove: false,
                showSuccessStake: false,
                showSuccessApprove: false,
                showLoader: false,
                showError: false,
                showPools: false,
                showPoolLimitReached: false,
                showInputError: false,
                showCalculator: false,
                stakeTokenContract: null,
                farmTokenContract: null,
                stakeTokenBalance: 0,
                farmTokenBalance: 0,
                tokensTotalStakeForPool: {},
            };
        },
        computed: {
            ...mapState(["currentAddress", "tokensTotalStake", "currentBlockchain", "penaltyInfo", "allContractsData"]),

            totalPoolStaking() {
                return this.pool && this.tokensTotalStake ? this.tokensTotalStake[this.pool.contractId][this.pool.address.toLowerCase()] : 0;
            },
            blockchainSymbol() {
                return this.currentBlockchain === 56 ? "BSC" : this.currentBlockchain === 1 ? "ETH" : "HARM";
            },
        },

        mounted() {
            this.lang.init();
            let _this = this;

            setTimeout(async function init() {
                try {
                    if (_this.isLive === true) {
                        const { address: farmTokenAddress } = _this.stakeTokens.find((el) => el.address.toLowerCase() === _this.pool.address.toLowerCase())
                            .farmTokensList[0];

                        _this.stakeTokenContract = new ethers.Contract(_this.pool.address, tokenAbi, _this.$root.core.provider).connect(
                            _this.currentAddress != "0x0000000000000000000000000000000000000000" ? _this.$root.core.signer : _this.$root.core.provider
                        );
                        _this.farmTokenContract = new ethers.Contract(farmTokenAddress, tokenAbi, _this.$root.core.provider).connect(
                            _this.currentAddress != "0x0000000000000000000000000000000000000000" ? _this.$root.core.signer : _this.$root.core.provider
                        );
                        await _this.getUserBalance();
                    }
                } catch (error) {
                    console.log(error);
                    setTimeout(init, 300);
                }
            }, 300);
        },

        methods: {
            getBlockchainDex(chainId) {
                if (Number(chainId) === 56) {
                    return `https://pancakeswap.finance/add/BNB/`;
                }
            },
            prepareCalculator(pool) {
                this.showCalculator = false;
                this.tokensTotalStakeForPool = {};

                this.tokensTotalStakeForPool[pool.contractId] = {};
                if (pool.chainId) {
                    this.tokensTotalStakeForPool[pool.contractId][pool.address.toLowerCase()] = this.allContractsData.find(
                        (el) => el.contract_identifier.toLowerCase() === pool.contractAddressDB.toLowerCase()
                    ).data_obj.totalStaked[pool.address.toLowerCase()];
                } else {
                    this.tokensTotalStakeForPool[pool.contractId][pool.address.toLowerCase()] = this.allContractsData.find(
                        (el) =>
                            el.contract_identifier.toLowerCase() ===
                            `${this.currentBlockchain}_${conf[this.currentBlockchain].CONTRACT_VERSION_CONFIG[pool.contractId].POOL_ADDRESS.toLowerCase()}`
                    ).data_obj.totalStaked[pool.address.toLowerCase()];
                }

                this.showCalculator = true;
            },
            tokenTicker(pool, tokenName) {
                if (!pool.chainId) {
                    return conf[this.currentBlockchain].tokenAliases[tokenName];
                }
                return conf[pool.chainId].tokenAliases[tokenName];
            },
            getPenaltyPercentForPool(pool) {
                if (this.penaltyInfo[pool.contractId][pool.address.toLowerCase()]) {
                    return (
                        this.penaltyInfo[pool.contractId][pool.address.toLowerCase()].penaltyPercent /
                        Config.CONTRACT_VERSION_CONFIG[pool.contractId].PENALTY_PRECISION
                    );
                }
            },
            getPenaltyDuration(pool) {
                if (this.penaltyInfo[pool.contractId][pool.address.toLowerCase()]) {
                    return this.penaltyInfo[pool.contractId][pool.address.toLowerCase()].timeLeft > 0
                        ? Math.round(this.penaltyInfo[pool.contractId][pool.address.toLowerCase()].timeLeft / 24 / 3600)
                        : "0";
                }
            },
            poolEndsIn(pool) {
                if (!pool.chainId || pool.chainId === this.currentBlockchain) {
                    const currentDate = Math.floor(new Date().getTime() / 1000);
                    const daysPassed = Math.floor((currentDate - Config.CONTRACT_VERSION_CONFIG[pool.contractId].POOL_START_TIME) / 3600 / 24);
                    return Config.CONTRACT_VERSION_CONFIG[pool.contractId].STAKE_DAYS - daysPassed;
                } else {
                    const currentDate = Math.floor(new Date().getTime() / 1000);
                    const daysPassed = Math.floor((currentDate - conf[pool.chainId].CONTRACT_VERSION_CONFIG[pool.contractId].POOL_START_TIME) / 3600 / 24);
                    return conf[pool.chainId].CONTRACT_VERSION_CONFIG[pool.contractId].STAKE_DAYS - daysPassed;
                }
            },
            maxStakeDays(pool) {
                if (pool.chainId) {
                    const res = conf[pool.chainId].CONTRACT_VERSION_CONFIG[pool.contractId].STAKE_DAYS;
                    return res;
                }
                return Config.CONTRACT_VERSION_CONFIG[pool.contractId].STAKE_DAYS;
            },
            getFarmTokens(pool) {
                if (!pool.chainId || pool.chainId === this.currentBlockchain) {
                    if (pool && pool.name) {
                        let stakeTokenData = Config.stakeTokens.filter((token) => token.name === pool.name && token.contractId === pool.contractId);
                        return stakeTokenData[0].farmTokensList;
                    } else {
                        return false;
                    }
                } else {
                    if (pool && pool.name) {
                        let stakeTokenData = conf[pool.chainId].stakeTokens.filter((token) => token.name === pool.name && token.contractId === pool.contractId);

                        return stakeTokenData[0].farmTokensList;
                    } else {
                        return false;
                    }
                }
            },
            redirectToStake() {
                this.$root.core.fetchLastStakes(this.currentAddress, this.pool.contractId);
                this.$root.core.updateUserInfo(this.currentAddress, 20000);
                setTimeout(() => this.$router.push({ name: "stake" }), 2000);
            },

            poolStrength(pool) {
                if (!pool.chainId || pool.chainId === this.currentBlockchain) {
                    const poolAddress = pool.address.toLowerCase();
                    const totalMaxStake = Config.stakeTokens.find(
                        (token) => token.address.toLowerCase() === poolAddress.toLowerCase() && pool.contractId === token.contractId
                    ).totalMaxStake;
                    const poolTotalStake = this.tokensTotalStake[pool.contractId][poolAddress];
                    const res = (poolTotalStake / totalMaxStake) * 100 > 100 ? 100 : (poolTotalStake / totalMaxStake) * 100;
                    return this.$root.core.withoutRound(res);
                } else {
                    if (this.allContractsData.length > 0) {
                        const poolAddress = pool.address.toLowerCase();
                        const totalMaxStake = pool.totalMaxStake;
                        const contractDataObj = this.allContractsData.find(
                            (el) => el.contract_identifier.toLowerCase() === pool.contractAddressDB.toLowerCase()
                        );
                        const poolTotalStake = contractDataObj.data_obj.totalStaked[poolAddress];
                        const res = (poolTotalStake / totalMaxStake) * 100 > 100 ? 100 : (poolTotalStake / totalMaxStake) * 100;
                        return this.$root.core.withoutRound(res);
                    } else {
                        return "0.00";
                    }
                }
            },
            poolAPYPercentage(pool, stakeAmount = 0) {
                if (pool.poolTotalStake === 0) {
                    return "N/A";
                } else {
                    if (!pool.chainId || pool.chainId === this.currentBlockchain) {
                        const poolAddress = pool.address.toLowerCase();
                        const poolTotalStake = this.tokensTotalStake[pool.contractId][poolAddress];
                        const resCoef = (100 * pool.coefTokens) / Number(poolTotalStake) + Number(stakeAmount);
                        return this.$root.core.withoutRound(resCoef) === "0.00" ? "N/A" : resCoef !== NaN ? this.$root.core.withoutRound(resCoef) : "N/A";
                    } else {
                        if (this.allContractsData.length > 0) {
                            const poolAddress = pool.address.toLowerCase();
                            const contractDataObj = this.allContractsData.find(
                                (el) => el.contract_identifier.toLowerCase() === pool.contractAddressDB.toLowerCase()
                            );
                            const poolTotalStake = contractDataObj.data_obj.totalStaked[poolAddress];
                            const resCoef = (100 * pool.coefTokens) / Number(poolTotalStake) + Number(stakeAmount);
                            return this.$root.core.withoutRound(resCoef) === "0.00" ? "N/A" : resCoef !== NaN ? this.$root.core.withoutRound(resCoef) : "N/A";
                        } else {
                            return "0.00";
                        }
                    }
                }
            },
            tokensInPool(pool) {
                if (!pool.chainId || pool.chainId === this.currentBlockchain) {
                    const poolAddress = pool.address.toLowerCase();
                    const totalMaxStake = Config.stakeTokens.find(
                        (token) => token.address.toLowerCase() === poolAddress && pool.contractId === token.contractId
                    ).totalMaxStake;
                    return [Number(this.tokensTotalStake[pool.contractId][poolAddress]).toFixed(0), totalMaxStake];
                } else {
                    if (this.allContractsData.length > 0) {
                        const poolAddress = pool.address.toLowerCase();
                        const totalMaxStake = pool.totalMaxStake;
                        const contractDataObj = this.allContractsData.find(
                            (el) => el.contract_identifier.toLowerCase() === pool.contractAddressDB.toLowerCase()
                        );
                        return [Number(contractDataObj.data_obj.totalStaked[poolAddress]).toFixed(0), totalMaxStake];
                    } else {
                        return [0, pool.totalMaxStake];
                    }
                }
            },
            async addTokenToMetaMask(token) {
                if (window.ethereum && localStorage.getItem("selectedWallet") === "metamask") {
                    try {
                        // wasAdded is a boolean. Like any RPC method, an error may be thrown.
                        await window.ethereum.request({
                            method: "wallet_watchAsset",
                            params: {
                                type: "ERC20",
                                options: {
                                    address: token.address,
                                    symbol: token.tag,
                                    decimals: token.decimals || 18, // The number of decimals in the token
                                    image: `${conf.REF_BASE}tokens/${token.tag.toLowerCase()}.png`,
                                },
                            },
                        });
                    } catch (error) {
                        console.log(error);
                    }
                } else if (window.localStorage.getItem("selectedWallet") === "walletconnect") {
                    try {
                        await this.$root.core.provider.provider.request({
                            method: "wallet_watchAsset",
                            params: {
                                type: "ERC20",
                                options: {
                                    address: token.address,
                                    symbol: token.tag,
                                    decimals: token.decimals || 18, // The number of decimals in the token
                                    image: `${conf.REF_BASE}tokens/${token.tag.toLowerCase()}.png`, // A string url of the token logo
                                },
                            },
                        });
                    } catch (error) {
                        console.log(error);
                    }
                } else if (window.ethereum && localStorage.getItem("selectedWallet") === "onto") {
                    try {
                        // wasAdded is a boolean. Like any RPC method, an error may be thrown.
                        await window.onto.request({
                            method: "wallet_watchAsset",
                            params: {
                                type: "ERC20",
                                options: {
                                    address: token.address,
                                    symbol: token.tag,
                                    decimals: token.decimals || 18, // The number of decimals in the token
                                    image: `${conf.REF_BASE}tokens/${token.tag.toLowerCase()}.png`,
                                },
                            },
                        });
                    } catch (error) {
                        console.log(error);
                    }
                }
            },
            async getUserBalance() {
                if (!this.pool.chainId) {
                    let resultStakeTokenBalance = await this.stakeTokenContract.balanceOf(this.currentAddress);
                    let resultFarmTokenBalance = await this.farmTokenContract.balanceOf(this.currentAddress);

                    let stakeTokenDecimals = Config.tokens.find((el) => el.address.toLowerCase() === this.pool.address.toLowerCase()).decimals;

                    const { decimals: farmTokenDecimals } = this.stakeTokens.find((el) => el.address === this.pool.address).farmTokensList[0];
                    if (
                        this.pool &&
                        (this.pool.address.toLowerCase() === "0xf74ee1e10e097dc326a2ad004f9cc95cb71088d3" ||
                            this.pool.address.toLowerCase() === "0x21b64d891805b0c6437e8209146e60ad87ebb499")
                    ) {
                        this.stakeTokenBalance = this.$root.core.withoutRound(ethers.utils.formatUnits(resultStakeTokenBalance._hex, stakeTokenDecimals), 8);
                        this.farmTokenBalance = this.$root.core.withoutRound(ethers.utils.formatUnits(resultFarmTokenBalance._hex, farmTokenDecimals));
                    } else {
                        this.stakeTokenBalance = this.$root.core.withoutRound(ethers.utils.formatUnits(resultStakeTokenBalance._hex, stakeTokenDecimals));
                        this.farmTokenBalance = this.$root.core.withoutRound(ethers.utils.formatUnits(resultFarmTokenBalance._hex, farmTokenDecimals));
                    }
                } else {
                    this.stakeTokenBalance = 0;
                    this.farmTokenBalance = 0;
                }
            },
            async changeNetwork(symbol) {
                await this.$root.core.changeNetwork(this, symbol.toLowerCase());
            },
            getBlockchainExplorer(currentBlockchain) {
                return currentBlockchain === 56
                    ? `https://bscscan.com/address/`
                    : currentBlockchain === 1666600000
                    ? `https://explorer.harmony.one/address/`
                    : currentBlockchain === 4
                    ? `https://etherscan.io/address/`
                    : "";
            },
        },
        watch: {
            showCalculator: function (next) {
                const body = document.body;
                if (next === true) {
                    body.style.overflow = "hidden";
                } else {
                    body.style.overflow = "auto";
                }
            },
            showStakeWindow: function (next) {
                const body = document.body;
                if (next === true) {
                    body.style.overflow = "hidden";
                } else {
                    body.style.overflow = "auto";
                }
            },
        },
    };
</script>
