<template>
    <div class="pool-bg">
        <div class="responsive pool-bg-des">
            <div class="referal">
                <div class="discription">
                    <div class="discription__wrapper">
                        <div class="pool__icon"></div>
                        <h1>{{ lang.get("POOL_TITLE") }}</h1>
                    </div>
                    <p class="referal_text">{{ lang.get("POOL_DESC") }}</p>
                </div>
                <div class="patric__foto"></div>
            </div>
            <div class="settings">
                <div class="for_me">
                    <div @click="changeType($event)" class="settings__left">
                        <button class="first__button" value="all" :class="{ activ: contractType === 'all' }">All</button>
                        <button class="button__two" value="liquidity" :class="{ activ: contractType === 'liquidity' }">Liquidity staking</button>
                        <button class="button__there" value="farming" :class="{ activ: contractType === 'farming' }">FARMING</button>
                    </div>
                    <div class="settings__center">
                        <button @click="isLive = true" class="button__live" :class="{ activ: isLive }">Live</button>
                        <button @click="isLive = false" class="button__finish" :class="{ activ: !isLive }">Finished</button>
                    </div>
                </div>
                <div class="settings__right">
                    <div
                        v-if="isLive === true"
                        class="dropdown"
                        @click.stop="showSortBy = !showSortBy"
                        :class="{ active: showSortBy, 'drop-active': showSortBy }"
                    >
                        <div class="dropdown-select">
                            <span v-if="sortBy === ''" class="select">Sort by</span>
                            <span v-else class="select">{{
                                sortBy === "apr" ? "APR" : sortBy === "staked" ? "Days Staked" : sortBy === "completion" ? "Completion" : "A-Z"
                            }}</span>

                            <i class="i-arrow-drop-down-line"></i>
                        </div>
                        <div class="dropdown-list">
                            <div @click.stop="(sortBy = 'apr'), (showSortBy = !showSortBy)" class="dropdown-list__item up">APR</div>
                            <div @click.stop="(sortBy = 'staked'), (showSortBy = !showSortBy)" class="dropdown-list__item">Days Staked</div>
                            <div @click.stop="(sortBy = 'completion'), (showSortBy = !showSortBy)" class="dropdown-list__item down">Completion</div>
                            <div @click.stop="(sortBy = 'alphabet'), (showSortBy = !showSortBy)" class="dropdown-list__item down">A-Z</div>
                        </div>
                    </div>
                    <!-- <div class="dropdown act" @click="showSelectToken = !showSelectToken" :class="{ active: showSelectToken }">
                    <div v-if="!selectedToken" class="dropdown-select">
                        <span class="select">Select a token</span>
                        <i class="i-arrow-drop-down-line"></i>
                    </div>
                    <div v-else class="dropdown-select">
                        <span class="select">{{ tokenTicker(selectedToken) }}</span>
                        <i class="i-arrow-drop-down-line"></i>
                    </div>
                    <div class="dropdown-list">
                        <div v-for="token in searchToken()" :key="token.address" :value="token.address" :name="token.name" @click="setupToken($event)">
                            <div class="token_img" :class="token.name" :value="token.address" :name="token.name"></div>
                            <div class="dropdown-list__item" :value="token.address" :name="token.name">{{ tokenTicker(token.name) }}</div>
                            <i v-if="selectedToken && selectedToken === token.name" class="i-check-full" :value="token.address" :name="token.name"></i>
                        </div>
                    </div>
                </div> -->

                    <input type="text" placeholder="Search pools" class="input__type" v-model="searchTokenName" />
                </div>
            </div>
            <div class="row">
                <div
                    class="row__name"
                    :class="{ 'any-network': !currentAddress || currentAddress === '0x0000000000000000000000000000000000000000' || unsupportedChainId() }"
                >
                    <div class="row__name__token">
                        <span>{{ lang.get("TOKEN_NAME") }}</span>
                        <div class="row__days__text">
                            <i @click.stop() class="i-question-line"></i>
                            <span class="question-line-text">
                                {{ lang.get("LP_TOKEN_INFO") }}
                            </span>
                        </div>
                    </div>

                    <div class="first__reward">
                        <p class="row__reward">Reward</p>
                        <div class="row__days__text">
                            <i @click.stop() class="i-question-line"></i>
                            <span class="question-line-text">
                                {{ lang.get("REWARD_?") }}
                            </span>
                        </div>
                    </div>
                    <div @click.stop() class="first__apr">
                        <p class="row__apr">{{ lang.get("APR") }}</p>
                        <div class="row__days__text">
                            <i class="i-question-line"></i>
                            <span class="question-line-text">
                                {{ lang.get("APR_INFO_?") }}
                            </span>
                        </div>
                    </div>
                    <div class="first__days">
                        <p class="row__days">{{ lang.get("DAYS_STAKED") }}</p>
                        <div class="row__days__text">
                            <i @click.stop() class="i-question-line"></i>
                            <span class="question-line-text">
                                {{ lang.get("DAYS_STAKED_?") }}
                            </span>
                        </div>
                    </div>
                    <div class="first__ends">
                        <p class="row__ends">{{ lang.get("POOL_STRENGTH") }}</p>
                        <div class="row__days__text">
                            <i @click.stop() class="i-question-line"></i>
                            <span class="question-line-text">
                                {{ lang.get("ENDS_WITH_?") }}
                            </span>
                        </div>
                    </div>
                    <div class="first__reward-chain">
                        <p class="row__reward">Blockchain</p>
                        <div class="row__days__text">
                            <i @click.stop() class="i-question-line"></i>
                            <span class="question-line-text">
                                {{ lang.get("CHAIN_?") }}
                            </span>
                        </div>
                    </div>
                    <!-- <div class="empty__block"></div> -->
                </div>
            </div>
            <div v-if="showLoader && currentAddress" class="table__loader">
                <i class="i-loader-4-line"></i>
            </div>
            <pool-stats
                v-if="(!currentAddress || currentAddress === '0x0000000000000000000000000000000000000000' || unsupportedChainId()) && !showLoader"
                :contractType="contractType"
                :unsupportedChainId="unsupportedChainId()"
                @connectWallet="$emit('connectWallet')"
                :sortBy="sortBy"
                :searchTokenName="searchTokenName"
                :finishedPools="finishedPools"
                :isLive="isLive"
                data-cy="wrapper-pools"
            />
            <div
                data-cy="wrap-pool"
                v-else-if="(currentAddress || currentAddress !== '0x0000000000000000000000000000000000000000' || unsupportedChainId()) && !showLoader"
            >
                <pool-details-card
                    v-if="isLive === true"
                    v-for="(pool, index) in poolsForContractType"
                    :isLive="isLive"
                    :key="pool.contractId + index + pool.address"
                    :pool="pool"
                    :stakeTokens="poolsToShow"
                    data-cy="wrapper-pools"
                />
                <pool-details-card
                    v-if="isLive === false"
                    v-for="pool in poolsForContractType"
                    :isLive="isLive"
                    :key="pool.id + pool.POOL_END_TIME"
                    :pool="pool"
                    :stakeTokens="stakeTokens"
                />
            </div>
            <div
                v-if="
                    (isLive === true && searchTokenName && poolsForContractType.length === 0) ||
                    (isLive === false && searchTokenName && finishedPools.length === 0 && currentAddress !== '0x0000000000000000000000000000000000000000')
                "
                class="how__to__earn empty__card"
            >
                <!-- <div class="cat"></div> -->
                <div class="search-icon"></div>
                <p class="first__cat__text">No tokens found</p>
                <p class="two__cat__text"></p>
            </div>
            <div
                v-if="
                    (isLive === false && finishedPools.length === 0 && !unsupportedChainId() && !searchTokenName) ||
                    (isLive === false && poolsForContractType.length === 0 && !unsupportedChainId() && !searchTokenName)
                "
                class="how__to__earn empty__card"
            >
                <div class="search-icon"></div>
                <p class="first__cat__text">No finished pools</p>
            </div>
            <div
                v-if="
                    isLive === true &&
                    currentAddress !== '0x0000000000000000000000000000000000000000' &&
                    poolsForContractType.length === 0 &&
                    !unsupportedChainId() &&
                    contractType === 'all' &&
                    !showLoader &&
                    !searchTokenName
                "
                class="how__to__earn empty__card"
            >
                <div class="search-icon"></div>
                <p class="first__cat__text">No active pools</p>
            </div>
            <div v-if="selectedToken && isLive === true && contractType === 'liquidity' && poolsForContractType.length === 0" class="how__to__earn empty__card">
                <div class="search-icon"></div>
                <p class="first__cat__text">No liquidity pools for this token</p>
            </div>
            <div
                v-if="
                    isLive === true &&
                    contractType === 'liquidity' &&
                    poolsForContractType.length === 0 &&
                    !searchTokenName &&
                    currentAddress !== '0x0000000000000000000000000000000000000000'
                "
                class="how__to__earn empty__card"
            >
                <div class="search-icon"></div>
                <p class="first__cat__text">No liquidity pools</p>
            </div>
            <div
                v-if="isLive === true && contractType === 'farming' && poolsForContractType.length === 0 && !searchTokenName"
                class="how__to__earn empty__card"
            >
                <div class="search-icon"></div>
                <p class="first__cat__text">No farming pools</p>
            </div>
            <!-- <div v-if="isLive === false && contractType === 'farming' && poolsForContractType.length === 0" class="how__to__earn empty__card">
                <div class="search-icon"></div>
                <p class="first__cat__text">No farming pools</p>
            </div> -->
            <div v-if="selectedToken && isLive === true && contractType === 'farming' && poolsForContractType.length === 0" class="how__to__earn empty__card">
                <div class="search-icon"></div>
                <p class="first__cat__text">No farming pools for this token</p>
            </div>
        </div>
    </div>
</template>

<script>
    import MultiLang from "@/core/multilang";

    import { mapState } from "vuex";

    import ShowAddInfoModal from "../../../components/modalWindows/ShowAddInfoModal.vue";
    import PoolStats from "../../../components/PoolStats.vue";

    import Loader from "../../../components/Loader.vue";
    import conf from "../../../../Config.json";
    import PoolDetailsCard from "../../../components/PoolDetailsCard.vue";

    var Config = conf[window.localStorage.getItem("selectedNetwork")] || conf[conf.PRIMARY_BLOCKCHAIN.chainId];

    export default {
        components: {
            Loader,
            ShowAddInfoModal,
            PoolStats,
            PoolDetailsCard,
        },
        name: "Pool",
        data: function () {
            return {
                lang: new MultiLang(this),
                showSelectToken: false,
                showSortBy: false,
                tokenAddress: "",
                tokenName: "",
                tokenInput: "",
                stakeTokenContract: null,
                farmTokenContract: null,
                selectedToken: "",
                stakeTokenBalance: 0,
                farmTokenBalance: 0,
                tokenDecimals: 0,
                stakeAmount: "",
                lowBalance: false,
                incorrectAmount: false,
                showAnimation: true,
                selectPool: false,
                showPoolLimitExceedError: false,
                minStakeAmount: false,

                showAllPoolStatsModal: false,
                showTokensList: false,
                showStakeWindow: false,

                showAddInfoModal: false,
                showPoolDetails: true,

                pools: [],
                poolData: null,
                poolsForContractType: [],
                farmsTokens: [],
                poolsTotalStakeData: {},
                finishedPools: [],
                validReferrer: false,
                sortBy: "",
                searchTokenName: "",

                contractType: "all",
                isLive: true,
                showLoader: true,
            };
        },
        mounted() {
            this.lang.init();
            let _this = this;
            if (!this.poolsToShow.length) {
                this.showLoader = true;
            }
            setTimeout(function loaderStatus() {
                if (_this.poolsToShow.length && _this.currentAddress === "0x0000000000000000000000000000000000000000") {
                    _this.showLoader = false;
                    return;
                } else if (
                    Object.keys(_this.allContractsData).length &&
                    _this.currentAddress &&
                    _this.currentAddress !== "0x0000000000000000000000000000000000000000"
                ) {
                    _this.showLoader = false;
                    return;
                } else {
                    setTimeout(loaderStatus, 300);
                    return;
                }
            }, 1200);

            // setTimeout(function checkIfTotalStakeAvailable(){
            //   if(_this.currentAddress == '0x0000000000000000000000000000000000000000' && _this.tokensTotalStake.V1){
            //     _this.showAllPoolStatsModal = true
            //     _this.showAnimation = false
            //   }else {
            //     setTimeout(checkIfTotalStakeAvailable, 500)
            //   }
            // }, 1000)

            setTimeout(function fetchTokens() {
                try {
                    if (!_this.poolsToShow || !_this.poolsToShow.length || !_this.stakeTokens.length) {
                        throw new Error();
                    }
                    _this.poolsForContractType = _this.poolsToShow;

                    return;
                } catch (error) {
                    setTimeout(fetchTokens, 300);
                }
            }, 300);
            setTimeout(async function updateComponentData() {
                try {
                    setTimeout(updateComponentData, 10000);
                    return;
                } catch (error) {
                    console.log(error);
                    setTimeout(updateComponentData, 300);
                    return;
                }
            }, 300);
        },

        computed: {
            ...mapState(["currentAddress", "stakeTokens", "tokensTotalStake", "currentBlockchain", "penaltyInfo", "allContractsData"]),
            tokenImg() {
                //TODO check the address and set the proper class with icon
            },

            poolsNoWallet() {
                return conf[this.currentBlockchain].tokens;
            },

            maxStake() {
                if (this.poolData) {
                    return this.poolData.totalMaxStake - this.totalPoolStaking;
                }
            },

            poolsToShow() {
                const allChains = conf.SUPPORTED_BLOCKCHAINS.filter((el) => el !== this.currentBlockchain);
                const allPools = [];
                for (let chain of allChains) {
                    allPools.push(
                        conf[chain].stakeTokens.map((el) => {
                            const contractAddressDB = chain + "_" + conf[chain].CONTRACT_VERSION_CONFIG[el.contractId].POOL_ADDRESS.toLowerCase();
                            const POOL_END_TIME = conf[chain].CONTRACT_VERSION_CONFIG[el.contractId].POOL_END_TIME;
                            const POOL_START_TIME = conf[chain].CONTRACT_VERSION_CONFIG[el.contractId].POOL_START_TIME;
                            return {
                                ...el,
                                chainId: chain,
                                blockchainSymbol: chain === 56 ? "BSC" : chain === 1 ? "ETH" : "HARM", // todo support more chains
                                contractAddressDB,
                                POOL_END_TIME,
                                POOL_START_TIME,
                            };
                        })
                    );
                }
                const currentTime = new Date().getTime() / 1000;
                const flattenArray = allPools.flat();
                const inactivePools = flattenArray.filter((el) => el.POOL_END_TIME > currentTime);

                const reversedArray = this.stakeTokens.filter((el) => Config.CONTRACT_VERSION_CONFIG[el.contractId].POOL_END_TIME > currentTime);
                const finishedPoolsForCurrentChain = this.stakeTokens.filter((el) => Config.CONTRACT_VERSION_CONFIG[el.contractId].POOL_END_TIME < currentTime);

                const finishedPoolsForOtherChains = flattenArray.filter((el) => el.POOL_END_TIME < currentTime);
                this.finishedPools = [...finishedPoolsForCurrentChain, ...finishedPoolsForOtherChains];
                if (this.contractType === "all") {
                    const activePools = reversedArray.sort((a, b) => b.id - a.id);

                    return [...activePools, ...inactivePools];
                } else {
                    const reversedAndFiltered = reversedArray.filter((el) => el.contractType === this.contractType).sort((a, b) => b.id - a.id);
                    return [...reversedAndFiltered, ...inactivePools.filter((el) => el.contractType === this.contractType)];
                }
            },
        },

        methods: {
            hideOption(event) {
                try {
                    event.stopPropagation();

                    var el = event.target.parentNode;
                    el.style.display = "none";
                    setTimeout(function () {
                        el.style.removeProperty("display");
                    }, 300);
                } catch (error) {
                    console.log(error);
                }
            },
            changeType(event) {
                this.contractType = event.target.value;
                this.sortBy = "";
                this.searchTokenName = "";
                console.log("type changed");

                if (this.isLive === true) {
                    if (this.contractType === "all") {
                        this.poolsForContractType = this.poolsToShow.filter((token) => token.tag.includes(this.searchTokenName.toUpperCase()));
                    } else {
                        this.poolsForContractType = this.poolsToShow
                            .filter((el) => el.contractType === event.target.value)
                            .filter((token) => token.tag.includes(this.searchTokenName.toUpperCase()));
                    }
                } else {
                    if (this.contractType === "all") {
                        this.poolsForContractType = this.finishedPools;
                    } else {
                        this.poolsForContractType = this.finishedPools.filter((el) => el.contractType === event.target.value);
                    }
                }
            },

            unsupportedChainId() {
                const selectedWallet = window.localStorage.getItem("selectedWallet");
                if (selectedWallet === "metamask" && window.ethereum) {
                    if (Number(window.ethereum.chainId) > 0) {
                        return conf.SUPPORTED_BLOCKCHAINS.indexOf(Number(window.ethereum.chainId || 0)) < 0;
                    } else {
                        setTimeout(this.unsupportedChainId, 300);
                        return true;
                    }
                } else if (selectedWallet === "onto" && window.onto) {
                    if (Number(window.onto.chainId) > 0) {
                        return conf.SUPPORTED_BLOCKCHAINS.indexOf(Number(window.onto.chainId || 0)) < 0;
                    } else {
                        setTimeout(this.unsupportedChainId, 300);
                        return true;
                    }
                } else if (selectedWallet === "walletconnect") {
                    const WC_Obj = JSON.parse(window.localStorage.getItem("walletconnect"));
                    return conf.SUPPORTED_BLOCKCHAINS.indexOf(Number(WC_Obj?.chainId)) < 0;
                }
                return true;
            },
            searchToken() {
                return Config.tokens.filter((token) => token.name.includes(this.tokenInput.toUpperCase()));
            },

            getFarmTokens(pool) {
                if (this.isLive === true) {
                    if (this.stakeTokens && this.selectedToken) {
                        let stakeTokenData = this.stakeTokens.filter((token) => token.name === this.selectedToken && token.contractId === pool.contractId);

                        return stakeTokenData[0].farmTokensList;
                    } else {
                        return false;
                    }
                } else {
                    return pool.farmTokensList;
                }
            },
            isPoolOpen(pool) {
                let currentTime = Math.floor(new Date().getTime() / 1000);
                return Boolean(currentTime < Config.CONTRACT_VERSION_CONFIG[pool.contractId].POOL_END_TIME);
            },

            async setupToken(token) {
                this.selectedToken = token.target.attributes.name.value;
            },

            async setMaxValue() {
                if (this.poolData && this.stakeTokenBalance > this.poolData.totalMaxStake - this.totalPoolStaking) {
                    this.stakeAmount = this.poolData.totalMaxStake - this.totalPoolStaking;
                } else {
                    this.stakeAmount = this.stakeTokenBalance;
                }

                await this.getTokensRates();
            },

            async getTokensRates() {
                try {
                    if (this.tokenAddress) {
                        for (let [index, contractDetails] of Object.entries(Config.CONTRACT_VERSION_CONFIG)) {
                            let contractId = index;

                            this.poolsTotalStakeData[`${contractId}TotalStake`] = this.tokensTotalStake[contractId][this.tokenAddress.toLowerCase()];
                        }

                        const pools = this.stakeTokens.filter((pool) => pool.address.toLowerCase() === this.tokenAddress.toLowerCase());

                        for (let i = 0; i < pools.length; i++) {
                            if (pools[i].referrerStatus === undefined) {
                                const referrerAddress = localStorage.getItem("ref") || "";

                                if (referrerAddress.length === 42 && referrerAddress !== this.currentAddress) {
                                    const res = await this.$root.core.checkReferrer(referrerAddress, pools[i].contractId);
                                    pools[i]["referrerStatus"] = res;
                                } else {
                                    pools[i]["referrerStatus"] = false;
                                }
                            }
                        }
                        const currentDate = Math.floor(new Date().getTime() / 1000);
                        pools.forEach((poolData, idx) => {
                            for (let i = 0; i < poolData.farmTokensList.length; i++) {
                                const userHourlyReard =
                                    (Number(this.stakeAmount) * poolData.farmTokensList[i].rewardRatePerBlock) /
                                    (this.poolsTotalStakeData[`${poolData.contractId}TotalStake`] + Number(this.stakeAmount));

                                const currentDate = Math.floor(new Date().getTime() / 1000);
                                const daysPassed = Math.floor((currentDate - Config.CONTRACT_VERSION_CONFIG[poolData.contractId].POOL_START_TIME) / 3600 / 24);

                                const possibleReward =
                                    Number(userHourlyReard * 24) *
                                    (Config.CONTRACT_VERSION_CONFIG[poolData.contractId].STAKE_DAYS - daysPassed - poolData.farmTokensList[i].interval);

                                if (poolData.referrerStatus) {
                                    poolData.farmTokensList[i].possibleReward = Number(possibleReward) * 0.95;
                                } else {
                                    poolData.farmTokensList[i].possibleReward = Number(possibleReward) * 0.9;
                                }
                            }

                            poolData.poolTotalStake = this.poolsTotalStakeData[`${poolData.contractId}TotalStake`];
                        });

                        //reseting the array so that data has changed

                        this.pools = pools.sort((a, b) => b.id - a.id);
                    }
                } catch (error) {
                    console.log(error);
                }
            },

            tokenTicker(tokenName) {
                return Config.tokenAliases[tokenName];
            },
        },

        watch: {
            sortBy: function (newVal) {
                try {
                    if (newVal === "apr") {
                        const currentChainPools = this.poolsForContractType.filter((el) => el.chainId === undefined);
                        const otherChainPools = this.poolsForContractType.filter((el) => el.chainId);

                        currentChainPools.sort((a, b) => {
                            const aValue = (100 * a.coefTokens) / this.tokensTotalStake[a.contractId][a.address.toLowerCase()];
                            const bValue = (100 * b.coefTokens) / this.tokensTotalStake[b.contractId][b.address.toLowerCase()];

                            return bValue - aValue;
                        });
                        otherChainPools.sort((a, b) => {
                            const contractDataObjA = this.allContractsData.find(
                                (el) => el.contract_identifier.toLowerCase() === a.contractAddressDB.toLowerCase()
                            );
                            const contractDataObjB = this.allContractsData.find(
                                (el) => el.contract_identifier.toLowerCase() === b.contractAddressDB.toLowerCase()
                            );
                            const totalStakedA = contractDataObjA.data_obj.totalStaked[a.address.toLowerCase()];
                            const totalStakedB = contractDataObjB.data_obj.totalStaked[b.address.toLowerCase()];
                            const aValue = (100 * a.coefTokens) / totalStakedA;
                            const bValue = (100 * b.coefTokens) / totalStakedB;

                            return bValue - aValue;
                        });
                        this.poolsForContractType = [...currentChainPools, ...otherChainPools];
                    } else if (newVal === "staked") {
                        const currentChainPools = this.poolsForContractType.filter((el) => el.chainId === undefined);
                        const otherChainPools = this.poolsForContractType.filter((el) => el.chainId);
                        currentChainPools.sort((a, b) => {
                            const POOL_START_TIME_A = Config.CONTRACT_VERSION_CONFIG[a.contractId].POOL_START_TIME;
                            const POOL_START_TIME_B = Config.CONTRACT_VERSION_CONFIG[b.contractId].POOL_START_TIME;

                            return POOL_START_TIME_B - POOL_START_TIME_A;
                        });
                        otherChainPools.sort((a, b) => {
                            const POOL_START_TIME_A = a.POOL_START_TIME;
                            const POOL_START_TIME_B = b.POOL_START_TIME;

                            return POOL_START_TIME_B - POOL_START_TIME_A;
                        });
                        this.poolsForContractType = [...currentChainPools, ...otherChainPools];
                    } else if (newVal === "completion") {
                        const currentChainPools = this.poolsForContractType.filter((el) => el.chainId === undefined);
                        const otherChainPools = this.poolsForContractType.filter((el) => el.chainId);
                        currentChainPools.sort((a, b) => {
                            const totalStakedA = this.tokensTotalStake[a.contractId][a.address.toLowerCase()];
                            const totalStakedB = this.tokensTotalStake[b.contractId][b.address.toLowerCase()];

                            const maxStakeA = a.totalMaxStake;
                            const maxStakeB = b.totalMaxStake;

                            const completionPercA = (totalStakedA / maxStakeA) * 100;
                            const completionPercB = (totalStakedB / maxStakeB) * 100;

                            return completionPercA - completionPercB;
                        });
                        otherChainPools.sort((a, b) => {
                            const contractDataObjA = this.allContractsData.find(
                                (el) => el.contract_identifier.toLowerCase() === a.contractAddressDB.toLowerCase()
                            );
                            const contractDataObjB = this.allContractsData.find(
                                (el) => el.contract_identifier.toLowerCase() === b.contractAddressDB.toLowerCase()
                            );
                            const totalStakedA = contractDataObjA.data_obj.totalStaked[a.address.toLowerCase()];
                            const totalStakedB = contractDataObjB.data_obj.totalStaked[b.address.toLowerCase()];

                            const maxStakeA = a.totalMaxStake;
                            const maxStakeB = b.totalMaxStake;

                            const completionPercA = (totalStakedA / maxStakeA) * 100;
                            const completionPercB = (totalStakedB / maxStakeB) * 100;

                            return completionPercA - completionPercB;
                        });
                        this.poolsForContractType = [...currentChainPools, ...otherChainPools];
                    } else if (newVal === "alphabet") {
                        this.poolsForContractType.sort((a, b) => a.tag.localeCompare(b.tag));
                    }
                } catch (error) {
                    console.log(error);
                }
            },

            searchTokenName: function () {
                if (this.isLive === true) {
                    if (!this.searchTokenName) {
                        this.poolsForContractType = this.poolsToShow;
                    } else {
                        this.poolsForContractType = this.poolsToShow.filter((token) => token.tag.includes(this.searchTokenName.toUpperCase()));
                    }
                } else if (this.isLive === false) {
                    const currentTime = new Date().getTime() / 1000;
                    if (!this.searchTokenName) {
                        this.poolsForContractType = this.finishedPools;
                    } else {
                        this.poolsForContractType = this.finishedPools.filter((token) => token.tag.includes(this.searchTokenName.toUpperCase()));
                    }
                }
            },
            isLive: function (newVal) {
                if (newVal === true) {
                    this.poolsForContractType = this.poolsToShow;
                } else if (newVal === false) {
                    this.sortBy = "";
                    if (this.contractType === "all") {
                        this.poolsForContractType = this.finishedPools;
                    } else if (this.contractType === "liquidity") {
                        this.poolsForContractType = this.finishedPools.filter((el) => el.contractType === this.contractType);
                    } else if (this.contractType === "farming") {
                        this.poolsForContractType = this.finishedPools.filter((el) => el.contractType === this.contractType);
                    }
                }
            },
        },
    };
</script>
