<template>
      <div  class="modal">
      <div class="modal-wrapper">
          <div class="modal-header">
              <div class="title">What are LP Tokens</div>
              <button @click="$emit('close')" data-cv="btn-close-switch-wallet"> <i class="i-close"></i></button>
          </div>
          <div class="modal-content">
            <p v-if="tokenAddress" class="addinfo addinfo_modal">
              LP (liquidity pool) tokens are the tokens that liquidity providers receive for adding funds to a liquidity pool.
              <br/><br/>Our liquidity pools currently has four tokens: BRNG, APYS, ROAD, and HGET.
              <br/><br/>How to Get LP Tokens
              <br/><br/>Become a liquidity provider to start earning LP tokens today! You can use
              <br/><br/><a v-if="tokenAddress" target="_blank" :href="`https://pancakeswap.finance/add/BNB/${tokenAddress}`">PancakeSwap</a> for Binance Smart Chain.
              
              <br/><br/> Learn <a target="_blank" href="https://www.youtube.com/watch?v=GPBwHSDXoWc&t=1s">how to stake</a> on our liquidity pool today 
          </p>  
          <p v-else class="addinfo addinfo_modal">
              LP (liquidity pool) tokens are the tokens that liquidity providers receive for adding funds to a liquidity pool.
              <br/><br/>Our liquidity pools currently have four tokens: BRNG, APYS, ROAD, and HGET.
              <br/><br/><span class="modal-block-title">How to Get LP Tokens :</span>
              <br/><br/>Become a liquidity provider to start earning LP tokens today! You can use
              <a>PancakeSwap</a> for Binance Smart Chain.              
              <br/><br/> Learn <a target="_blank" href="https://www.youtube.com/watch?v=w2RSeDMFZsg&ab_channel=bRing.Finance">how to stake</a> on our liquidity pool today 
          </p>  
          </div>
      </div>

    </div>
</template>

<script>
import MultiLang from '@/core/multilang'; 
export default {
  // name: "ConnectWalletModal",
  props: ['tokenAddress'],
  data: function() {
    return {
      lang: new MultiLang(this),
    }
  },
  computed: {
  },
  methods: {
  },
  mounted() {
  this.lang.init()
}
}
</script>