<template>
    <div class="mod">
        <div class="modal__inner">
            <div class="modal__up">
                <p class="modal__name">{{ lang.get("ROI_CALC") }}</p>
                <i @click="$emit('close')" class="i-close-line"></i>
            </div>
            <div class="modal__down">
                <div class="block__one" :class="{ 'red-error': showError1 === true }">
                    <div class="first__stake">
                        <p class="your__reward">{{ lang.get("STAKE") }}</p>
                        <div class="row__days__text">
                            <i class="i-question-line"></i>
                            <span class="question-line-text">
                                {{ lang.get("POSSIBLE_STAKE_?") }}
                            </span>
                        </div>
                    </div>
                    <div class="max__stake">
                        <div class="max__div">
                            <input class="max__input" type="number" placeholder="0.00" v-model="inputAmountCrypto" id="number" /><span class="usd">{{
                                tokenTicker(pool.name)
                            }}</span>
                        </div>
                        <!-- <button class="i-arrow-left-right-line"></button> -->
                    </div>
                    <p class="balance__rio">{{ inputCryptoToUsd }} <span> USD</span></p>
                </div>
                <p v-if="showError1" class="error">{{ errorMessage1 }}</p>

                <div class="block__second">
                    <div class="second__text">
                        <p class="limit">{{ lang.get("BASE_APR") }}</p>
                        <p class="limit__number">{{ baseApr > 0 ? baseApr : 0 }}%</p>
                    </div>
                    <div class="second__text">
                        <p class="limit">{{ lang.get("DAYS_LEFT") }}</p>
                        <p class="limit__number">{{ poolEndsIn > 0 ? poolEndsIn : 0 }}</p>
                    </div>
                    <div class="more__link">
                      <a  href="https://bridge.bring.finance/" target="_blank" class="tooltip bridg__link">
                        <a class="tooltiptext">Bridge Token</a>
                      </a>
                      <a  :href="`https://pancakeswap.finance/add/BNB/${pool.address}`" target="_blank" class="tooltip cake__link">
                        <a class="tooltiptext">Pancake</a>
                      </a>
                    </div>
                </div>
                <div class="block__one__two" :class="{ 'red-error': showError2 === true }">
                    <div class="first__stake">
                        <p class="your__reward">{{ lang.get("EXPECTED_REWARD") }}</p>
                        <div class="row__days__text">
                            <div class="row__days__text">
                                <i class="i-question-line"></i>
                                <span class="question-line-text">
                                    {{ lang.get("POSSIBLE_REWARD_?") }}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="max__stake">
                        <p class="max__name">
                            <input class="max__input" disabled type="number" placeholder="0.00" v-model="outputAmountUsd" /><span class="usd">usd</span>
                        </p>
                        <i v-if="!realPoolAPYPercentage(pool) || !pool" class="i-loader-4-fill"></i>
                    </div>
                    <!-- <p class="balance__rio">0.00 <span>BRNG LP</span></p> -->
                </div>
                <p v-if="showError2" class="error">{{ errorMessage2 }}</p>
                <div class="rule">
                    <ul>
                        <li>{{ lang.get("SIDE_NOTE_1") }}</li>
                        <li>{{ lang.get("SIDE_NOTE_2") }}</li>
                        <li>{{ lang.get("SIDE_NOTE_3") }}</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import MultiLang from "@/core/multilang";
    import { mapState } from "vuex";
    import conf from "../../../Config.json";
    var Config = conf[window.localStorage.getItem("selectedNetwork")] || conf[conf.PRIMARY_BLOCKCHAIN.chainId];
    export default {
        props: ["pool", "showCalculator", "tokensTotalStake"],
        computed: {
            ...mapState(["currentRate"]),
            poolEndsIn() {
                //TODO: change when multiple contracts added
                if (this.pool.chainId) {
                    const currentDate = Math.floor(new Date().getTime() / 1000);
                    const daysPassed = Math.floor(
                        (currentDate - conf[this.pool.chainId].CONTRACT_VERSION_CONFIG[this.pool.contractId].POOL_START_TIME) / 3600 / 24
                    );
                    return conf[this.pool.chainId].CONTRACT_VERSION_CONFIG[this.pool.contractId].STAKE_DAYS - daysPassed;
                } else {
                    const currentDate = Math.floor(new Date().getTime() / 1000);
                    const daysPassed = Math.floor((currentDate - Config.CONTRACT_VERSION_CONFIG[this.pool.contractId].POOL_START_TIME) / 3600 / 24);
                    return Config.CONTRACT_VERSION_CONFIG[this.pool.contractId].STAKE_DAYS - daysPassed;
                }
            },
            inputCryptoToUsd() {
                return this.pool && Number(this.inputAmountCrypto) > 0
                    ? (Number(this.inputAmountCrypto) * this.getTokenPrice(this.pool.address.toLowerCase())).toFixed(2)
                    : 0;
            },
        },
        data: function () {
            return {
                lang: new MultiLang(this),
                inputAmountCrypto: "",
                inputAmountUsd: 0,
                outputAmountUsd: 0,
                baseApr: this.realPoolAPYPercentage(this.pool),
                showError1: false,
                errorMessage1: "",
                showError2: false,
                errorMessage2: "",
            };
        },
        mounted() {
            const number = document.getElementById("number");

            // Listen for input event on numInput.
            number.onkeydown = function (e) {
                if (
                    !(
                        (e.keyCode > 95 && e.keyCode < 106) ||
                        (e.keyCode > 47 && e.keyCode < 58) ||
                        e.keyCode === 8 ||
                        e.keyCode === 37 ||
                        e.keyCode === 39 ||
                        e.keyCode === 188 ||
                        e.keyCode === 190
                    )
                ) {
                    return false;
                }
            };
        },
        methods: {
            tokenTicker(tokenName) {
                return this.pool.chainId ? conf[this.pool.chainId].tokenAliases[tokenName] : Config.tokenAliases[tokenName];
            },
            getTokenPrice(tokenAddress) {
                return this.currentRate && this.currentRate[tokenAddress.toLowerCase()] ? this.currentRate[tokenAddress.toLowerCase()] : 0.1;
            },
            realPoolAPYPercentage(pool, stakeAmount = 0) {
                if (pool.poolTotalStake === 0) {
                    return "N/A";
                } else {
                    const poolAddress = pool.address.toLowerCase();
                    const poolTotalStake = this.tokensTotalStake[pool.contractId][poolAddress];
                    const resCoef = (pool.maxProfitPercent * pool.coefTokens) / (Number(poolTotalStake) + Number(stakeAmount));

                    return this.$root.core.withoutRound(resCoef) === "0.00" ? "N/A" : resCoef !== NaN ? this.$root.core.withoutRound(resCoef) : "N/A";
                }
            },
        },

        watch: {
            inputAmountCrypto: function (newVal, oldValue) {
                var numbers = newVal.toString().split(".");
                var preDecimal = numbers[0];
                var postDecimal = numbers[1];
                const stringNum = newVal.toString();
                const hasDot = stringNum.includes(".");
                if (hasDot) {
                    if (stringNum.length > 11) {
                        this.showError1 = true;
                        this.errorMessage1 = `Max stake amount must not have more than 10 digits`;
                        this.inputAmountCrypto = oldValue;
                        setTimeout(() => {
                            this.showError1 = true;
                            this.errorMessage1 = "Max stake amount must not have more than 10 digits";
                        }, 100);
                        setTimeout(() => {
                            this.showError1 = false;
                            this.errorMessage1 = "";
                        }, 3000);
                        return;
                    }
                } else {
                    if (stringNum.length > 10) {
                        this.showError1 = true;
                        this.errorMessage1 = `Max stake amount must not have more than 10 digits`;
                        this.inputAmountCrypto = oldValue;
                        setTimeout(() => {
                            this.showErro1r = true;
                            this.errorMessage1 = "Max stake amount must not have more than 10 digits";
                        }, 100);
                        setTimeout(() => {
                            this.showError1 = false;
                            this.errorMessage1 = "";
                        }, 3000);
                        return;
                    }
                }

                if (Number(newVal) > this.pool.totalMaxStake) {
                    this.showError2 = true;
                    this.baseApr = 0;
                    this.outputAmountUsd = 0;
                    this.errorMessage2 = `The reward amount can’t be estimated - the amount entered exceeds the pool size`;
                    return;
                }

                if (postDecimal?.length > 4) {
                    this.inputAmountCrypto = oldValue;

                    setTimeout(() => {
                        this.showError1 = true;
                        this.errorMessage1 = "Max decimal limit reached. Allowed decimals count is 4";
                    }, 100);
                    setTimeout(() => {
                        this.showError1 = false;
                        this.errorMessage1 = "";
                    }, 3000);
                    return;
                }
                this.showError1 = false;
                this.showError2 = false;
                this.errorMessage1 = "";
                this.errorMessage2 = "";
                let amountInUsd = 0;
                for (let rewardToken of this.pool.farmTokensList) {
                    this.baseApr = this.realPoolAPYPercentage(this.pool, Number(newVal));
                    let amountCrypto = (Number(newVal) * Number(this.realPoolAPYPercentage(this.pool, Number(newVal)))) / 100;

                    let tokenPrice = Number(this.getTokenPrice(rewardToken.address));
                    amountInUsd += tokenPrice * amountCrypto;
                }

                this.outputAmountUsd = amountInUsd > 0 ? amountInUsd.toFixed(2) : 0;
            },
        },
    };
</script>
